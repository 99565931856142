import React from 'react';

import { Paths } from '@utils/paths/paths';
import { PathsConfig } from '@utils/paths/paths-config';

import config from '@services/config/server';
import { getCachedOffersForCountry } from '@services/http/offer/get-cached-offers';
import { getCachedPlansForCountry } from '@services/http/plan/get-cached-plans';
import { ServerSideHttpService } from '@services/http/server-http';
import { splitLocale } from '@services/locale-config';
import SsrLogger from '@services/logger/ssrLogger';
import schemaOrg from '@services/schema.org';
import { getStory, getGlobalStories, getPhrases, getBlogCategories, getComponents } from '@services/storyblok';
import { useStoryblok } from '@services/storyblok/bridge';
import { Page } from '@theme/Page/Page';

const pageId = Paths.Home;
const ssrLogger = new SsrLogger(`bm:pages:storyblok:${pageId}`);

export const HomePage = ({ story, preview, locale, pageContext }) => {
  const finalStory = useStoryblok(story, preview);

  return <Page story={finalStory} locale={locale} pageContext={pageContext} />;
};

export default HomePage;

export const getServerSideProps = async context => {
  try {
    const {
      preview = false,
      params: { locale },
      req,
      res,
    } = context;
    const { country, language } = splitLocale(locale);

    const storyblokSlug = PathsConfig[pageId].storyblokSlug[country];

    ssrLogger.info(`Getting page content with locale ${locale}`);
    const { data } = await getStory({
      slug: storyblokSlug,
      locale,
      preview,
      resolve: ['categoryCard.category'],
    });

    ssrLogger.info(`Getting global content with locale ${locale} for page ${storyblokSlug}`);
    const globalStories = await getGlobalStories({ locale, preview });

    const serverHttpService = new ServerSideHttpService({
      language,
      country,
      serverSideRequest: req,
      serverSideResponse: res,
    });

    ssrLogger.info(`Getting phrases with locale ${locale} for page ${storyblokSlug}`);
    const phrases = await getPhrases({
      locale,
      preview,
    });

    ssrLogger.info(`Getting all plans with locale ${locale}`);

    const allOffers = await getCachedOffersForCountry(serverHttpService, country);
    const allPlans = await getCachedPlansForCountry(serverHttpService, country);
    const { blogCategories } = await getBlogCategories({ locale, preview });

    const components = await getComponents({ locale, preview });

    return {
      props: {
        story: data ? data.story : false,
        preview,
        locale,
        pageContext: {
          schemaOrg: schemaOrg.homepage({ language, country, appUrl: config.appUrl }),
          allPlans,
          allOffers,
          globalStories,
          currentPageId: pageId,
          phrases,
          components,
          blogCategories,
        },
      },
    };
  } catch (error) {
    ssrLogger.error(error);

    return {
      notFound: true,
    };
  }
};
